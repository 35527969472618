import React from 'react';
import { Box, Divider, List, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Item from '../../components/Item';
import { grey } from '@mui/material/colors';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    pageContent: {
        margin: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
    },
}));

const installItems = [
    {
        title: 'First Things First! Install the App',
        path: '/install'
    }
];

const customizeItems = [
    {
        title: 'Customize RedFlag Rules',
        path: '/customize_rules'
    }
];

function Collection() {
    const classes = useStyles();
    const textColor = 'black';

    return (
        <Box className={classes.pageContent}>
            <Box sx={{
                width: '100%',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderRadius: '8px',
                borderColor: grey[400],
                padding: 1,
                my: 3
            }}>
                <Typography varaint="h2" sx={{ fontSize: 24, fontWeight: 'bold', padding: 2 }}>
                    Installing the App
                </Typography>
                <Divider sx={{ marginLeft: 2 }} />
                <List>
                    {installItems.map((item, index) =>
                        <Item
                            key={index}
                            item={item}
                            textColor={textColor}
                        />
                    )}
                </List>
            </Box>


            <Box sx={{
                width: '100%',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderRadius: '8px',
                borderColor: grey[400],
                padding: 1,
                my: 3
            }}>
                <Typography varaint="h2" sx={{ fontSize: 24, fontWeight: 'bold', padding: 2 }}>
                    Customizations
                </Typography>
                <Divider sx={{ marginLeft: 2 }} />
                <List >
                    {customizeItems.map((item, index) => (
                        <Item
                            key={index}
                            item={item}
                            textColor={textColor}
                        />
                    ))}
                </List>
            </Box>
        </Box>
    )

}

export default Collection;