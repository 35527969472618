import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useStyles } from './styles';
import role from '../../imgs/role.jpg';
import addresses from '../../imgs/addresses.jpg';
import address from '../../imgs/address.jpg';
import email from '../../imgs/email.jpg';
import ip from '../../imgs/ip.jpg';
import phone from '../../imgs/phone.jpg';
import country from '../../imgs/country.png';

const CustomizeRules = () => {
    const classes = useStyles();

    return (
        <>
            <Grid container className={classes.top} spacing={2} sx={{ mt: 8, mb: 8, mx: 32 }}>

                <Grid item xs={12}>
                    <div>
                        <Typography varaint="h1" display="inline" sx={{ fontSize: 45, paddingTop: 2 }} >
                            Customize Red Flag Rules
                        </Typography>
                    </div>

                    <Typography sx={{ fontSize: 20, color: '#666666', fontFamily: "Slabien", my: 2 }}>
                        Red Flag Chargeback Orders by Email, Address, IP, Phone
                    </Typography>

                </Grid>
            </Grid >
            <main className={classes.helpContent}>
                <Grid container spacing={2} >

                    <Grid item xs={12} >
                        <Typography varaint="h3" display="inline" sx={{ fontSize: 28, paddingTop: 2 }} >
                            You must be logged in as Store Owner or Admin
                        </Typography>
                        <p className={classes.paragraph}>
                            To begin using SpotFraud, please first log in to SpotFraud via Bigcommerce Apps/SpotFraud or
                            on spotfraud.app website as either Store owner or Admin.
                        </p>

                        <img src={role} alt={"Store Owner/Administrator"} sx={{ marginLeft: 'auto', marginRight: 'auto' }} className={classes.normalImg} ></img >

                        <p className={classes.paragraph}>
                            Only the Store Owner or Admin in SpotFraud has the ability to install apps. 
                            You can identify the Store Owners or Admins in SpotFraud by navigating to Settings > Users.
                        </p>
                        <p className={classes.paragraph}>
                            <strong>Note:</strong>We only flag orders according to RedFlag Rules and notify merchants.
                            Our app does not cancel the order automatically.
                        </p>
                    </Grid>

                    <Grid item xs={12} >
                        <Typography varaint="h3" display="inline" sx={{ fontSize: 28, paddingTop: 2 }} >
                            1. Red Flag by Email
                        </Typography>
                        <p className={classes.paragraph}>
                            {"To block a particular email, please do the following:\n" +
                                "1, click 'RedFlag Rules'/Email on the left navigation panel;\n" +
                                "2, input the email in the Email box\n" +
                                "3, click 'Add' button on the right, the email will be added\n" +
                                "as shown in the following screenshot."
                            }
                        </p>
                        <img src={email} alt={"Red Flag Email"} sx={{ mx: 'auto' }} className={classes.normalImg} ></img >
                    </Grid>
                    <Grid item xs={12}>
                        <p className={classes.paragraph}>
                            If you encounter some chargeback, you can Red Flag the email of the chargeback as shown above to prevent
                            further recurring chargebacks.
                        </p>
                    </Grid>

                </Grid>

                <br />
                <br />
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <Typography varaint="h2" display="inline" sx={{ fontSize: 28, paddingTop: 8 }} >
                            2. Red Flag by Address
                        </Typography>
                        <p className={classes.paragraph}>
                            {"To block a particular address, please do the following:\n" +
                                "1, click 'RedFlag Rules'/Address on the left navigation panel;\n" +
                                "2, click 'Add Addr' button on the right, then a window would be poped up;\n" +
                                "3, fill in the country/city/state/zip and submit\n" +
                                "as shown in the following screenshot."
                            }
                        </p>
                        <img src={address} alt={"Red Flag Address"} sx={{ mx: 'auto' }} className={classes.normalImg} ></img >
                        <p className={classes.paragraph}>
                            Name is optional, but zip code is required.
                        </p>
                        <p className={classes.paragraph}>
                            The addres list can be edited or deleted as shown blow.
                        </p>
                        <img src={addresses} alt={"Blacked Address List"} sx={{ mx: 'auto' }} className={classes.normalImg} ></img >
                        <p className={classes.paragraph}>
                            You can also block a country to prevent all orders from a particular country.
                            Just click choose a country, and click "Redflag country" in the popup window in Step 3.
                            If one adds India to the blacklist, then all orders placed from India are flagged.
                        </p>
                        <img src={country} alt={"Red Flag Country"} sx={{ mx: 'auto' }} className={classes.normalImg} ></img >
                    </Grid>

                </Grid>
                <br />
                <br />
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <Typography varaint="h2" display="inline" sx={{ fontSize: 28, paddingTop: 8 }} >
                            3. Red Flag by IP
                        </Typography>
                        <p className={classes.paragraph}>
                            {"To block a particular ip, please do the following:\n" +
                                "1, click 'RedFlag Rules'/IP on the left navigation panel;\n" +
                                "2, input the ip in the IP box\n" +
                                "3, click 'Add' button on the right, the ip will be added\n" +
                                "as shown in the following screenshot."
                            }
                        </p>
                        <img src={ip} alt={"Red Flg IP"} sx={{ mx: 'auto' }} className={classes.normalImg} ></img >
                    </Grid>
                </Grid>
                <br />
                <br />
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <Typography varaint="h2" display="inline" sx={{ fontSize: 28, paddingTop: 8 }} >
                            4. Red Flag by Phone
                        </Typography>
                        <p className={classes.paragraph}>
                            {"To block a particular phone, please do the following:\n" +
                                "1, click 'RedFlag Rules'/Phone on the left navigation panel;\n" +
                                "2, Choose the country and input the phone number in correct format\n" +
                                "3, click 'Add' button on the right, the phone will be added\n" +
                                "as shown in the following screenshot."
                            }
                        </p>
                        <img src={phone} alt={"Red Flag Phone"} sx={{ mx: 'auto' }} className={classes.normalImg} ></img >
                    </Grid>

                </Grid>
            </main >
        </>
    );
};

export default CustomizeRules;