import * as React from 'react';
import Install from './views/Public/Install';
import Contact from './views/Public/Contact';
import CustomizeRules from './views/Public/CustomizeRules';
import Collection from './views/Public/Collection';

import {
  Route,
  Routes,
} from 'react-router-dom';

export default function BreadCrumbRoutes() {

  return (
    <Routes>
      <Route path="/">
        <Route index element={<Collection />} />
        <Route path="install" element={<Install />} />
        <Route path="customize_rules" element={<CustomizeRules />} />
        <Route path="contact" element={<Contact />} />
      </Route>
    </Routes >
  );
}