import React from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { purple } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

const StyledExpandMoreIcon = styled(ChevronRightIcon)(({ theme }) => ({
    color: purple[500],
    fontSize: 30
}));

const Item = ({ item, textColor }) => {
    return (
        <ListItem
            disablePadding
            sx={{
                display: 'block',
            }}
            component={Link}
            to={item.path}
        >
            <ListItemButton
                sx={{
                    minHeight: 48,
                    justifyContent: 'left',
                }}
            >
                <ListItemText primary={item.title} sx={{ color: textColor }} />
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        justifyContent: 'right',
                    }}
                >
                    <StyledExpandMoreIcon />
                </ListItemIcon>
            </ListItemButton>
        </ListItem>
    )
};

export default Item;