import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useStyles } from './styles';
import Button from '@mui/material/Button';
import owner from '../../imgs/owner.jpg';
import appStore from '../../imgs/appstore.jpg';
import appListing from '../../imgs/bigcommerce_app_listing.jpg';
import getApp from '../../imgs/getapp.jpg';

const Install = () => {
    const classes = useStyles();
    const bigcommerceLink = "https://login.bigcommerce.com/deep-links/marketplace/apps/53855";

    const handleClick = async (e) => {
        e.preventDefault();

        //point: only open an external link in a new tab, otherwise, it will return a Button Reference error
        window.open(bigcommerceLink, '_blank');
    };

    return (
        <>
            <Grid container className={classes.top} spacing={2} sx={{ mt: 8, mb: 8, mx: 32 }}>

                <Grid item xs={12}>
                    <div>
                        <Typography varaint="h1" display="inline" sx={{ fontSize: 45, paddingTop: 2 }} >
                            Install the App
                        </Typography>
                    </div>

                    <Typography sx={{ fontSize: 20, color: '#666666', fontFamily: "Slabien", my: 2 }}>
                        {`Connecting ${process.env.REACT_APP_NAME} to your BigCommerce store`}
                    </Typography>

                </Grid>
            </Grid >
            <main className={classes.helpContent}>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <Typography varaint="h2" display="inline" sx={{ fontSize: 28, paddingTop: 2 }} >
                            Quick Direct Method
                        </Typography>
                    </Grid>

                    <Grid item xs={12} >
                        <Typography varaint="h3" display="inline" sx={{ fontSize: 24, paddingTop: 2 }} >
                            1. You must be logged in as BC Store Owner
                        </Typography>
                        <p>
                            {`To begin using ${process.env.REACT_APP_NAME}, please first log in to your BigCommerce store with the store Owner credentials.`}
                        </p>

                        <img src={owner} alt={"Store Owner"} sx={{ marginLeft: 'auto', marginRight: 'auto' }} className={classes.normalImg} ></img >

                        <p>
                            Only the Store Owner in BC has the ability to install apps. You can identify the Store Owners in BC by navigating to Account Settings > Users.
                        </p>
                    </Grid>

                    <Grid item xs={12} >
                        <Typography varaint="h3" display="inline" sx={{ fontSize: 24, paddingTop: 2 }} >
                            2. Once logged into your store with BC Store Owner, click link below:
                        </Typography>
                        <p>
                            Click on button to begin Install:
                        </p>
                    </Grid>
                    <Grid item xs={12} className={classes.middle}>
                        <Button
                            variant="contained"
                            sx={{
                                width: '40%',
                                margin: 'auto',
                                textTransform: 'capitalize'
                            }}
                            onClick={handleClick}
                        >
                            {`Install ${process.env.REACT_APP_NAME}`}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <p>
                            If you’re already logged into your store in this browser,
                            ensure you’re using the proper user. Alternatively, right-click and open this URL in an incognito or private browser window,
                            and log in with the BC owner credentials at the installation point.
                        </p>
                    </Grid>

                </Grid>

                <br />
                <br />
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <Typography varaint="h2" sx={{ fontSize: 28, paddingTop: 8 }} >
                            BC Dashboard Method
                        </Typography>
                    </Grid>

                    <Grid item xs={12} >
                        <Typography varaint="h3" sx={{ fontSize: 24, paddingTop: 2 }} >
                            1. You must be logged in as BC Store Owner
                        </Typography>
                    </Grid>

                    <Grid item xs={12} >
                        <Typography varaint="h3" sx={{ fontSize: 24, paddingTop: 2 }} >
                            2. In BC Menu, click Apps > Marketplace :
                        </Typography>
                        <img src={appStore} alt={"Bigcommerce App Store"} sx={{ mx: 'auto' }} className={classes.normalImg} ></img >
                    </Grid>

                    <Grid item xs={12} >
                        <Typography varaint="h3" sx={{ fontSize: 24, paddingTop: 2 }} >
                            {`3. Search for ${process.env.REACT_APP_NAME} once in Marketplace`}
                        </Typography>
                        {'\n'}
                        <img src={appListing} alt={"Bigcommerce App Listing"} sx={{ mx: 'auto' }} className={classes.normalImg} ></img >
                    </Grid>

                    <Grid item xs={12} >
                        <Typography varaint="h3" sx={{ fontSize: 24, paddingTop: 2 }} >
                            4. Click "Get This App"
                        </Typography>
                        {'\n'}
                        <img src={getApp} alt={"Bigcommerce Install App"} sx={{ mx: 'auto' }} className={classes.normalImg} ></img >
                        <p>
                            Follow prompts to finish install.
                        </p>
                    </Grid>

                </Grid>
            </main >
        </>
    );
};

export default Install;