import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    top: {
        flexGrow: 1,
    },
    left: {
        textAlign: 'left',
    },
    pageContent: {
        marginLeft: theme.spacing(14),
        marginRight: theme.spacing(14),
        maxWidth: 'none',
        width: 'auto',
        display: 'block',
        textAlign: 'left'
    },
    helpContent: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(14),
        marginLeft: theme.spacing(34),
        marginRight: theme.spacing(34),
        maxWidth: 'none',
        width: 'auto',
        display: 'block',
        textAlign: 'left'
    },
    feature: {
        paddingBottom: theme.spacing(2),
        height: 300,
    },
    secondary: {
        color: theme.palette.text.secondary,
        textAlign: 'left'
    },
    middleSecondary: {
        color: theme.palette.text.secondary,
        textAlign: 'center',
    },
    middle: {
        textAlign: 'center',
    },
    productImg: {
        width: 50,
        borderWidth: '1px',
        borderColor: theme.palette.primary.light
    },
    paper: {
        textAlign: 'left',
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    officeImg: {
        width: 350,
        borderWidth: '1px',
        borderColor: theme.palette.primary.light
    },
    projectWrapper: {
        display: 'inline-block',
        padding: 20,
    },
    normalImg: {
        // width: 300,
        maxWidth: '100%',
        borderWidth: '1px',
        borderColor: theme.palette.primary.light
    },
    paragraph: {
        lineHeight: "28px"
    }
}));

