import React from 'react';
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material';
import BreadCrumbBar from './views/Public/BreadCrumbBar';
import Routes from './Routes';

function App() {
  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <BreadCrumbBar />
      <Routes />
    </ThemeProvider>
  );
}

export default App;